<template>
  <a-modal v-model="visible" :title="edit?'编辑用户':'添加用户'" @cancel="cancelMethod" @ok="addMethod" okText="确定" cancelText="取消">
    <p class="flex">
      <label class="type-name">{{companyId=='100'?'账号':'身份证号'}}</label> 
      <a-input class="flex1" v-model="addUser.account" placeholder="身份证号作为账号" :maxLength="18" />
    </p>
    <p class="flex" v-if="!edit">
      <label class="type-name">密码</label> 
      <a-input class="flex1" autocomplete="off" type="password" v-model="addUser.pwd" placeholder="密码" />
    </p>
    <p class="flex">
      <label class="type-name">姓名</label> 
      <a-input class="flex1" v-model="addUser.userName" placeholder="姓名" :maxLength="6" />
    </p>
    <p class="flex">
      <label class="type-name">手机号</label> 
      <a-input class="flex1" v-model="addUser.cell" placeholder="手机号" :maxLength="11" />
    </p>
    <p class="flex">
      <label class="type-name">角色</label> 
      <a-select class="flex1" v-model="addUser.roleId" >
        <a-select-option v-for="item in roleList" :value="item.roleId" :key="item.roleId">
          {{item.roleName}}
        </a-select-option>
      </a-select>
    </p>
    <p>
      <label class="type-name">状态</label>
      <a-switch :checked="addUser.userStatus" @click="addUser.userStatus = !addUser.userStatus" />
    </p>
  </a-modal>
</template>
<script>
export default {
  props:{
    user:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    edit:false,
    companyId:'',
    examSpotId:'',
    moduleId:{
      type:String,
      default:2
    }
  },
  data(){
    return {
      addUser:{
        companyId:this.$store.state.companyId,
        userStatus:true,
        userStatusId:1,
        moduleId:'2',
        roleId:''
      },
      roleList:[],
      visible:true
    }
  },
  mounted(){
    this.getRoleList()
    if(this.user.userId){
      this.addUser = Object.assign({},this.user)
    }
    if(this.companyId)this.addUser.companyId = this.companyId
  },
  methods:{
    cancelMethod(){
      this.$emit('close')
    },
    getRoleList(){
      this.$post(this.$url.角色列表,{
        companyId:this.companyId?this.companyId:'',
        moduleId:this.moduleId,
        examSpotId:this.examSpotId?this.examSpotId:''
      }).then(data => {
        this.roleList = data.data.list
      }).catch(() => {
          
      });
    },
    addMethod(){
      this.addUser.userStatusId = this.addUser.userStatus ? '1':'0'
      this.addUser.moduleId = this.moduleId
      this.addUser.examSpotId = this.examSpotId?this.examSpotId:''
      if(this.edit){
        this.editMethod();
        return
      }
      this.$post(this.$url.添加客户用户, this.addUser).then(data => {
        this.visible = false;
        this.$tips('添加成功','管理员添加成功！','success')
        this.$emit('addSuccess')
      }).catch((err) => {
        this.$tips('添加失败','请重新添加管理员！','error')
      });
    },
    editMethod(){
      this.$post(this.$url.用户编辑, this.addUser).then(data => {
        this.$tips('编辑成功','管理员编辑成功！','success')
        this.$emit('addSuccess')
      }).catch((err) => {
        this.$tips('编辑失败','请重新编辑管理员！','error')
      });
    }
  }
}
</script>