<template>
  <div>
    <a-tabs default-active-key="1" @change="callback">
      <a-tab-pane key="1" tab="用户管理">
        <a-button class="add-com-btn" @click="visible=true" type="primary">添加用户</a-button>  
        <a-table :columns="columns" :data-source="userList" :pagination="false">
          <a slot="name" slot-scope="text">{{ text }}</a>
          <span slot="customTitle"><a-icon type="smile-o" /> 用户名</span>
          <span slot="action" slot-scope="text, record">
            <a @click.prevent="editUser(record)">编辑</a>
            <a-divider type="vertical" />
            <span class="delete-btn" @click="remove(record)">删除</span>
          </span>
        </a-table>
        <!-- 添加用户 -->
        <add-user :user="addUser" :edit="edit"  v-if="visible" @addSuccess="getUserList();visible=false" @close="edit=false;visible=false" :companyId="companyId" moduleId="1" :examSpotId="$store.state.curSpotId" />
      </a-tab-pane>
      <a-tab-pane key="2" tab="角色管理" force-render>
        <roleManage :moduleId="1" :companyId="companyId" :examSpotId="$store.state.curSpotId" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import roleManage from '../../components/role'
import addUser from '../../components/addUser'
const columns = [
  {
    dataIndex: 'userName',
    key: 'userName',
    slots: { title: 'customTitle' },
    scopedSlots: { customRender: 'userName' },
  },
  {
    title: '电话',
    dataIndex: 'cell',
    key: 'cell',
  },
  {
    title: '身份证号',
    dataIndex: 'account',
    key: 'account',
  },
  {
    title: '角色名',
    dataIndex: 'roleName',
    key: 'roleName',
  },
  {
    title: '状态',
    dataIndex: 'statusName',
    key: 'statusName',
  },
  {
    title: '公司名',
    dataIndex: 'companyName',
    key: 'companyName',
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
];
export default {
  components:{
    roleManage,
    addUser
  },
  data() {
    return {
      visible:false,
      columns,
      userList:[],
      edit:false,
      addUser:{},
      companyId:sessionStorage.getItem("companyId")
    }
  },
  mounted(){
    
    this.getUserList()
  },
  methods: {
    editUser(item){
      this.edit = true
      this.addUser = Object.assign({},item)
      this.addUser.userStatus = this.addUser.statusId==1?true:false
      this.visible = true
    },
    callback(key) {
      console.log(key);
    },
    getUserList(){
      this.$post(this.$url.考场用户列表,{
        examSpotId:this.$store.state.curSpotId
      }).then(data => {
        this.userList = data.data.list
      }).catch(() => {
          
      });
    },
    //删除
    remove(item){
      this.$confirm({
        title:'是否确认删除？',
        content:`删除管理员之后，该账号将无法操作！`,
        onOk:()=>{
          this.$post(this.$url.用户删除, {
            userId:item.userId
          }).then(data => {
            this.$tips('删除成功','管理员账号删除成功！','success')
            this.getUser()
          }).catch(() => {
            this.$tips('删除失败','请重新操作！','error')
          });
        }
      })
    }
  },
}
</script>
<style scoped>

</style>