<template>
  <div>
    <a-button class="add-com-btn" type="primary" @click="edit=false;visible=true">添加角色</a-button>
    <a-table :columns="roleColums" :data-source="roleList" :pagination="false" style="max-width:500px">
      <span slot="action" slot-scope="text, record">
        <a @click="setPower(record)">权限</a>
        <a-divider type="vertical" />
        <a @click.prevent="editItem(record)" >编辑</a>
        <a-divider type="vertical" />
        <span class="delete-btn" @click="remove(record)">删除</span>
      </span>
    </a-table>
     <!--  添加角色  -->
    <a-modal v-model="visible" :title="edit?'编辑角色':'添加角色'" @cancel="visible=false" @ok="addMethod" okText="确定" cancelText="取消">
      <p class="flex">
        <label class="type-name">角色名</label> 
        <a-input class="flex1" v-model="addRole.roleName" placeholder="角色名" />
      </p>
    </a-modal> 
    <!-- 权限 -->
    <a-modal
      title="添加权限"
      :visible="power"
      okText="确定"
      cancelText="取消"
      @cancel="power = false"
      @ok="addPower"
    >
      <div class="">
        <div class="first-nav" v-for="item in treeData" :key="item.key">
          <span class="shousuo">
            <a-icon
              :type="item.showSecond ? 'caret-down' : 'caret-right'"
              @click="item.showSecond = !item.showSecond"
              
            />
          </span>
          <a-checkbox
            :indeterminate="item.indeterminate"
            :checked="item.isCheck"
            class="distance"
            @click="checkAll(item)"
          >
          </a-checkbox>
          <a-icon :type="item.icon" style="margin-left:10px"></a-icon>
          {{ item.title }}
          <div v-if="item.showSecond" class="second-nav">
            <!-- 二级 -->
            <span v-for="nav in item.children" :key="nav.key">
              <span
                v-if="nav.children && nav.children.length"
                style="display:block;"
              >
                <div class="first-nav">
                  <span class="shousuo" style="display:inline;">
                    <a-icon
                      :type="nav.showSecond ? 'caret-down' : 'caret-right'"
                      @click="nav.showSecond = !nav.showSecond"
                    />
                  </span>
                  <a-checkbox
                    :indeterminate="nav.indeterminate"
                    :checked="nav.isCheck"
                    class="distance"
                    @click="checkAllSec(nav,item)"
                  >
                  </a-checkbox>
                  <!-- <a-icon :type="nav.icon" style="margin-left:10px"></a-icon> -->
                  {{ nav.title }}
                  <div v-if="nav.showSecond" class="second-nav">
                    <!-- 三级 -->
                    <span v-for="thir in nav.children" :key="thir.key" class="thr-nav">
                      <a-checkbox
                        class="distance"
                        v-model="thir.isCheck"
                        @click="checkNav(thir, nav,item)"
                      >
                      </a-checkbox>
                      <a-icon :type="thir.icon"></a-icon>
                      {{ thir.title }}
                      <a-checkbox
                        class="edit-check"
                        v-for="(opr, index) in thir.operation"
                        :key="index"
                        v-model="opr.isCheck"
                      >
                        {{ opr.name }}
                      </a-checkbox>
                    </span>
                  </div>
                </div>
              </span>
              <span v-else class="sed-nav">
                <a-checkbox
                  class="distance"
                  v-model="nav.isCheck"
                  @click="checkNav(nav, item)"
                >
                </a-checkbox>
                <a-icon :type="nav.icon"></a-icon>
                {{ nav.title }}
                <a-checkbox
                  class="edit-check"
                  v-for="(opr, index) in nav.operation"
                  :key="index"
                  v-model="opr.isCheck"
                >
                  {{ opr.name }}
                </a-checkbox>
              </span>
            </span>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
const roleColums = [
  {
    title: '角色名',
    dataIndex: 'roleName',
    key: 'roleName',
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
]
export default {
  props:{
    moduleId:{
      type:Number,
      default:2
    },
    companyId:{
      type:String,
      default:''
    },
    examSpotId:{
      type:String,
      default:''
    }
  },
  data() {
    return {
      roleColums,
      roleList:[],
      roleCondition:{
        companyId:sessionStorage.getItem('companyId'),
        moduleId:'2'
      },
      visible:false,
      edit:false,
      addRole:{},
      power:false,
      treeData:[],
      roleId:''
    }
  },
  mounted(){
    this.getRoleList()
  },
  methods: {
    addPower() {
      this.menuList = []
      this.treeData.forEach(menu => {
        //一级
        if (menu.isCheck) {
          let chd = [];
          if (menu.children && menu.children.length) {
            menu.children.forEach(cild => {
              //二级
              if (cild.isCheck) {
                if (cild.children && cild.children.length) {
                  let sed = [];
                  cild.children.forEach(thir => {
                    if (thir.isCheck) {
                      let oprObj = {};
                      //三级
                      if (thir.operation && thir.operation.length) {
                        thir.operation.forEach(opt => {
                          if (opt.isCheck) {
                            oprObj[opt.key] = true;
                          }
                        });
                      }
                      sed.push({
                        menuId: thir.menuId,
                        path: thir.key,
                        name: thir.title,
                        icon: thir.icon,
                        meta: Object.assign({}, oprObj)
                      });
                    }
                  });
                  chd.push({
                    menuId: cild.menuId,
                    path: cild.key,
                    name: cild.title,
                    icon: cild.icon,
                    children: sed
                  });
                } else {
                  //只有二级
                  let oprObj = {};
                  if (cild.operation && cild.operation.length) {
                    cild.operation.forEach(opt => {
                      if (opt.isCheck) {
                        oprObj[opt.key] = true;
                      }
                    });
                  }
                  chd.push({
                    menuId: cild.menuId,
                    path: cild.key,
                    name: cild.title,
                    icon: cild.icon,
                    meta: Object.assign({}, oprObj)
                  });
                }
              }
            });
          }
          this.menuList.push({
            menuId: menu.menuId,
            path: menu.key,
            name: menu.title,
            meta: {
              icon: menu.icon
            },
            children: chd
          });
        }
      });
      //树结构改成平面结构
      let arr = []
      this.menuList.forEach(fir=>{
        arr.push({
          menuId:fir.menuId,
          menuName:fir.name,
          canAccess:true
        })
        if(fir.children && fir.children.length){
          fir.children.forEach(sed=>{
            arr.push({
              menuId:sed.menuId,
              menuName:sed.name,
              canAccess:true
            })
            if(sed.children && sed.children.length){
              sed.children.forEach(thir=>{
                arr.push({
                  menuId:thir.menuId,
                  menuName:thir.name,
                  canAccess:true
                })
              })
            }
          })
          
        }
      })
      this.$post(this.$url.设置菜单, {
        roleId: this.roleId,
        menus: arr
      }).then(() => {
        this.$success({
          okText: "确定",
          title: "成功",
          content: "用户组权限编辑成功！",
          onOk() {}
        });
        this.power = false;
        this.getRoleList();
      });
    },
    setPower(item){
      this.roleId = item.roleId
      this.$post(this.$url.角色菜单, {
        roleId:item.roleId,
      }).then(data => {
        this.power = true
        let menu = data.data.one.menus
        menu.forEach(fir=>{
          fir.showSecond = true;
          fir.isCheck = fir.canAccess
          if(fir.children && fir.children.length){
            fir.children.forEach(sed=>{
              sed.isCheck = sed.canAccess
              sed.showSecond = true
              if(sed.children && sed.children.length){
                sed.children.forEach(thir=>{
                  thir.isCheck = thir.canAccess
                  thir.showSecond = false
                })
              }
            })
          }
        })
        this.treeData = menu
      }).catch(() => {
        
      });
    },
    checkAll(item) {
      item.showSecond = true;
      item.isCheck = !item.isCheck;
      item.children.forEach(nav => {
        if (item.isCheck) {
          item.indeterminate = false;
          nav.isCheck = true;
          if (nav.operation && nav.operation.length) {
            nav.operation.forEach(opr => {
              opr.isCheck = true;
            });
          }
          if (nav.children && nav.children.length) {
            nav.children.forEach(thir => {
              thir.isCheck = true;
              if (thir.operation && thir.operation.length) {
                thir.operation.forEach(opr => {
                  opr.isCheck = true;
                });
              }
            });
          }
        } else {
          nav.isCheck = false;
          item.indeterminate = false;
          if (nav.operation && nav.operation.length) {
            nav.operation.forEach(opr => {
              opr.isCheck = false;
            });
          }
          if (nav.children && nav.children.length) {
            nav.children.forEach(thir => {
              thir.isCheck = false;
              if (thir.operation && thir.operation.length) {
                thir.operation.forEach(opr => {
                  opr.isCheck = false;
                });
              }
            });
          }
        }
      });
    },
    checkAllSec(nav,parent){
      nav.isCheck = !nav.isCheck;
      //检查是否全部选中
      let curd = 0;
      parent.children.forEach(mav => {
        if (mav.isCheck) {
          curd++;
        }
      });
      if (curd == parent.children.length) {
        //全部选中
        parent.indeterminate = false;
        parent.isCheck = true;
      } else if (curd > 0 && curd < parent.children.length) {
        //部分
        parent.indeterminate = true;
        parent.isCheck = true;
      } else {
        //一个也没有有
        parent.indeterminate = false;
        parent.isCheck = false;
      }
      /* 二级下的三级全部选中 */
      nav.children.forEach(secNav => {
        if (nav.isCheck) {
          secNav.isCheck = true;
          nav.indeterminate = false;
        } else {
          secNav.isCheck = false;
          nav.indeterminate = false;
        }
      });
    },
    checkNav(nav, parent,fir) {
      nav.isCheck = !nav.isCheck;
      if (nav.operation && nav.operation.length) {
        nav.operation.forEach(opr => {
          if (nav.isCheck) {
            opr.isCheck = true;
          } else {
            opr.isCheck = false;
          }
        });
      }
      //检查是否全部选中
      let curd = 0;
      parent.children.forEach(mav => {
        if (mav.isCheck) {
          curd++;
        }
      });
      if (curd == parent.children.length) {
        //全部选中
        parent.indeterminate = false;
        parent.isCheck = true;

      } else if (curd > 0 && curd < parent.children.length) {
        //部分
        parent.indeterminate = true;
        parent.isCheck = true;
        if(fir)fir.isCheck = true
        if(fir)fir.indeterminate = true
      } else {
        //一个也没有有
        parent.indeterminate = false;
        parent.isCheck = false;
        if(fir)fir.isCheck = false
        if(fir)fir.indeterminate = false
      }
      //检查二级是否全部选中
      if(fir){
        let firSelected = 0;
        let allSelected = 0;
        fir.children.forEach(two => {
          if (two.isCheck) {
            firSelected++;
          }
          if (!two.indeterminate) {
            allSelected++;
          }
        });
        if(fir.children.length == firSelected){
          if(allSelected ==fir.children.length ){
            //全部选中
            if(fir)fir.isCheck = true
            if(fir)fir.indeterminate = false
          }
        }
      }

    },
    remove(item){
      this.$confirm({
        title:'是否确认删除？',
        content:`删除角色之后，对应的用户也会被删除！`,
        onOk:()=>{
          this.$post(this.$url.删除角色, {
            roleId:item.roleId,
            moduleId:this.moduleId,
            examSpotId:this.examSpotId
          }).then(data => {
            this.$tips('删除成功','该角色删除成功！','success')
            this.getRoleList()
          }).catch(() => {
            this.$tips('删除失败','请重新操作！','error')
          });
        }
      })
    },
    editItem(item){
      this.edit = true;
      this.addRole = Object.assign({},item);
      this.visible = true
    },
    getRoleList(){
      this.$post(this.$url.角色列表,{
        companyId:this.companyId,
        moduleId:this.moduleId,
        examSpotId:this.examSpotId ? this.examSpotId:''
      }).then(data => {
        this.roleList = data.data.list
      }).catch(() => {
          
      });
    },
    editRole(item){
      this.$post(this.$url.角色编辑,this.addRole).then(data => {
        this.visible = false;
        this.$tips('操作成功','角色编辑成功！','success')
        this.getRoleList()
      }).catch(() => {
          
      });
    },
    addMethod(){
      this.addRole.moduleId = this.moduleId
      this.addRole.examSpotId = this.examSpotId ? this.examSpotId:''
      if(this.edit){
        this.editRole();
        return
      }
      this.$post(this.$url.角色添加, this.addRole).then(data => {
        this.visible = false;
        this.$tips('添加成功','角色添加成功！','success')
        this.getRoleList()
      }).catch((err) => {
        this.$tips('添加失败','请重新添加角色！','error')
      });
    },
  },
}
</script>
<style scoped>
.sed-nav{
  display:block;
  padding-left:0px;
  margin:8px 0;
}
.shousuo{
  margin-right:5px;
}
.second-nav{
  padding-left:30px;
  display: block;
}
.thr-nav{
  padding-left:0px;
  display:block;
  margin:8px 0;
}
</style>